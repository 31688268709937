import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getFormValues, isValid, initialize } from 'redux-form'
import { get, isEmpty, unionBy } from 'lodash'
import PropTypes from 'prop-types'
import qs from 'qs'

// components
import { compose } from 'redux'
import ElementLoading from '../../components/ElementLoading'
import UpravaPrilezitostiForm from '../../components/Prilezitosti/UpravaPrilezitosti/UpravaPrilezitostiForm'
import UpravaPrilezitostiConfirm from '../../components/Prilezitosti/UpravaPrilezitosti/UpravaPrilezitostiConfirm'

// actions
import * as SearchActions from '../../actions/SearchActions'
import * as PrilezitostiAction from '../../actions/PrilezitostiActions'
import * as ZmluvneVztahyActions from '../../actions/ZmluvneVztahyActions'

// utils
import { history } from '../../utils/history'
import { putReq } from '../../utils/request'
import { DATE_FORMAT, DOKUMENT_TYP, FORMS, PRILEZITOSTI_STAV } from '../../utils/enums'
import { PRILEZITOSTI, PRILEZITOSTI_DETAIL, setRouteParams } from '../../utils/routes'
import { formatDate } from '../../utils/date'
import { getNameOrCompany } from '../../utils/prilezitosti'
import { PERMISSIONS, withPermissions } from '../../utils/permissionsHoc'

function PrilezitostiUpravaPage(props) {
	const { computedMatch } = props
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const ciselniky = useSelector((state) => state.ciselniky.data)
	const interakcia = useSelector((state) => state.interakcie.detail.data)
	const formValues = useSelector((state) => getFormValues(FORMS.PRILEZITOSTI_UPRAVA)(state))
	const isFormValid = useSelector((state) => isValid(FORMS.PRILEZITOSTI_UPRAVA)(state))
	const originalValues = useSelector((state) => state.prilezitosti.prilezitost)

	const [step, setStep] = useState(1)
	const [stavOptions, setStavOptions] = useState([])
	const [agentOptions, setAgentOptions] = useState([])
	const [opOptions, setOpOptions] = useState([])
	const [zuOptions, setZuOptions] = useState([])
	const [isLoading, setIsLoading] = useState(false)

	const prilezitostId = computedMatch.params.cisloLead
	const cisloOP = computedMatch.params.cisloOP

	const { backUrl } = qs.parse(window.location.search, { ignoreQueryPrefix: true })

	let backUrlLink
	if (backUrl) {
		backUrlLink = atob(backUrl)
	}

	const parseOriginalFormValues = (originalValues) => {
		const leadOp = {
			label: getNameOrCompany(originalValues?.opLead?.meno, originalValues?.opLead?.priezvisko, originalValues?.opLead?.obchodneMeno),
			value: originalValues?.opLead?.cislo
		}
		// NOTE: set default option based on original lead OP value
		setOpOptions([leadOp])

		const suvisiaceZmluvy = originalValues?.vztahy?.map((vztah) => {
			return {
				label: `${vztah?.zmluvnyVztahCislo}: ${vztah?.typ?.nazov} - ${vztah?.komoditaTyp?.nazov} ${formatDate(vztah?.vytvorenyOd, '-', DATE_FORMAT.RENDER_DATE)}`,
				value: {
					zmluvnyVztahCislo: vztah?.zmluvnyVztahCislo,
					vytvorenyOd: vztah?.vytvorenyOd
				}
			}
		})
		// NOTE: set default option based on original lead OP value
		setZuOptions(suvisiaceZmluvy)

		return {
			stav: {
				label: originalValues?.stav?.nazov,
				value: originalValues?.stav?.id
			},
			leadOp,
			riesitel: originalValues?.riesitel,
			poznamkaInterna: originalValues?.poznamkaInterna,
			suvisiaceZmluvy
		}
	}

	useEffect(() => {
		dispatch(initialize(FORMS.PRILEZITOSTI_UPRAVA, parseOriginalFormValues(originalValues.data)))
	}, [originalValues])

	useEffect(() => {
		dispatch(PrilezitostiAction.loadDetailPrilezitosti(prilezitostId))
	}, [prilezitostId])

	useEffect(() => {
		if (!isEmpty(ciselniky?.ciselnikPrilezitostStav)) {
			const stavy = []
			ciselniky.ciselnikPrilezitostStav?.forEach((stav) => {
				if (stav.id !== PRILEZITOSTI_STAV.AKCEPTOVANA && stav.id !== PRILEZITOSTI_STAV.NEAKCEPTOVANA) {
					stavy.push({
						label: stav.nazov,
						value: stav.id
					})
				}
			})
			setStavOptions(stavy)
		}
	}, [ciselniky?.ciselnikPrilezitostStav])

	const fetchAgents = async (riesitel, prilezitostId) => {
		const agents = await dispatch(PrilezitostiAction.loadMoznosti(prilezitostId, { typ: 'riesitel', dopytujuci: riesitel }))
		if (!isEmpty(agents?.hodnota)) {
			setAgentOptions(
				agents?.hodnota.map((agent) => {
					return {
						label: agent,
						value: agent
					}
				})
			)
		}
	}

	useEffect(() => {
		if (interakcia?.riesitel && prilezitostId) {
			fetchAgents(interakcia.riesitel, prilezitostId)
		}
	}, [interakcia?.riesitel, prilezitostId])

	const loadOpOptions = async (search, callback) => {
		if (!search || search === '') {
			setOpOptions([])
			return
		}
		const op = await dispatch(SearchActions.searchUsers(search))
		const options = op?.payload?.obchodniPartneri?.content?.map((obchodnyPartner) => {
			return {
				label: obchodnyPartner?.obchodneMeno,
				value: obchodnyPartner?.cislo
			}
		})
		setOpOptions(options)
		callback(options)
	}

	const loadZuOptions = async (search, callback) => {
		if (!search || search === '') {
			setZuOptions([])
			return
		}

		const zu = await dispatch(
			ZmluvneVztahyActions.loadZmluvnyVztahMimoOP(false, {
				zuCislo: search,
				opCislo: cisloOP
			})
		)
		let options = zu?.zmluvneVztahy?.response?.content?.map((vztah) => {
			return {
				label: `${vztah?.cislo}: ${vztah?.typ?.nazov} - ${vztah?.komoditaTyp?.nazov} ${formatDate(vztah?.vytvorenyOd, '-', DATE_FORMAT.RENDER_DATE)}`,
				value: {
					zmluvnyVztahCislo: vztah?.cislo,
					vytvorenyOd: vztah?.vytvorenyOd
				}
			}
		})
		// NOTE: set default option based on from value suvisiaceZmluvy
		if (!isEmpty(formValues?.suvisiaceZmluvy)) {
			options = unionBy(options, formValues.suvisiaceZmluvy, 'value')
		}
		setZuOptions(options)
		callback(options)
	}

	const submitLead = async () => {
		if (!isFormValid) {
			return
		}
		try {
			setIsLoading(true)
			const body = {
				stav: {
					id: formValues?.stav.value,
					nazov: formValues?.stav.label
				},
				opLead: {
					cislo: formValues?.leadOp?.value
				},
				riesitel: formValues?.riesitel,
				poznamkaInterna: formValues?.poznamkaInterna,
				vztahy: formValues?.suvisiaceZmluvy?.map((vztah) => {
					return {
						zmluvnyVztahCislo: vztah?.value?.zmluvnyVztahCislo,
						prilezitostId,
						vytvorenyOd: vztah?.value?.vytvorenyOd
					}
				}),
				dokumenty: formValues?.dokumenty?.map((dokument) => {
					return {
						contentType: dokument?.type,
						typ: { id: DOKUMENT_TYP.VSTUPNY },
						data: dokument?.dataAsBase64,
						nazov: dokument?.name
					}
				})
			}
			await putReq(`/api/v0/prilezitosti/${prilezitostId}`, {}, body)
			history.push(setRouteParams(PRILEZITOSTI_DETAIL, formValues?.leadOp?.value, prilezitostId))
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e)
		} finally {
			setIsLoading(false)
		}
	}

	const isDisabled =
		originalValues.data?.stav?.id === PRILEZITOSTI_STAV.NEUSPESNA ||
		originalValues.data?.stav?.id === PRILEZITOSTI_STAV.UZAVRETA ||
		originalValues.data?.stav?.id === PRILEZITOSTI_STAV.AKCEPTOVANA ||
		originalValues.data?.stav?.id === PRILEZITOSTI_STAV.NEAKCEPTOVANA

	if (isLoading || originalValues?.isLoading) {
		return <ElementLoading />
	}

	return (
		<>
			{step === 1 && (
				<UpravaPrilezitostiForm
					title={`${t('translation:Prilezitosti.Krok 1 z 2')} : ${t('translation:Prilezitosti.Zmena priležitosti')}`}
					stavOptions={stavOptions}
					agentOptions={agentOptions}
					opOptions={opOptions}
					loadOpOptions={loadOpOptions}
					zuOptions={zuOptions}
					isDisabled={isDisabled}
					loadZuOptions={loadZuOptions}
					formValues={formValues}
					originalValues={originalValues.data}
					isFormValid={isFormValid}
					onBackClick={() => history.push(backUrlLink || setRouteParams(PRILEZITOSTI, get(interakcia, 'opCislo')))}
					onCancelClick={() => history.push(backUrlLink || setRouteParams(PRILEZITOSTI, get(interakcia, 'opCislo')))}
					onContinueClick={() => setStep(2)}
				/>
			)}
			{step === 2 && (
				<UpravaPrilezitostiConfirm
					title={`${t('translation:Prilezitosti.Krok 2 z 2')} : ${t('translation:Prilezitosti.Zmena priležitosti')}`}
					onBackClick={() => setStep(1)}
					onCancelClick={() => history.push(backUrlLink || setRouteParams(PRILEZITOSTI, get(interakcia, 'opCislo')))}
					formValues={formValues}
					onSubmit={submitLead}
					originalValues={originalValues.data}
				/>
			)}
		</>
	)
}

PrilezitostiUpravaPage.propTypes = {
	computedMatch: PropTypes.shape({
		params: PropTypes.shape({
			cisloOP: PropTypes.string.isRequired,
			cisloLead: PropTypes.string.isRequired
		}).isRequired
	}).isRequired
}

export default compose(withPermissions([PERMISSIONS.EDIT_LEADS_OWN, PERMISSIONS.EDIT_LEADS_BASE, PERMISSIONS.EDIT_LEADS_EXTENDED]))(PrilezitostiUpravaPage)
