import { capitalize, head, isEmpty, set } from 'lodash'
import { formatDate } from './date'

import { DATE_FORMAT, PRILEZITOSTI_FILTER_OPTIONS, PRILEZITOSTI_KEYS } from './enums'
import { checkPermissions, PERMISSIONS } from './permissionsHoc'

export const getColumns = (t) => {
	return [
		{
			title: t('components:Prilezitosti.ID'),
			name: 'id'
		},
		{
			title: t('components:Prilezitosti.Lead id'),
			name: PRILEZITOSTI_KEYS.LEAD
		},
		{
			title: t('components:Prilezitosti.Stav'),
			name: PRILEZITOSTI_KEYS.STAV
		},
		{
			title: t('components:Prilezitosti.Číslo OP'),
			name: PRILEZITOSTI_KEYS.CISLO_OP,
			notActive: true
		},
		{
			title: t('components:Prilezitosti.Meno'),
			name: PRILEZITOSTI_KEYS.MENO_OP,
			notActive: true
		},
		{
			title: t('components:Prilezitosti.Priezvisko'),
			name: PRILEZITOSTI_KEYS.PRIEZVISKO_OP,
			notActive: true
		},
		{
			title: t('components:Prilezitosti.Obchodný názov'),
			name: PRILEZITOSTI_KEYS.OBCHODNY_NAZOV,
			notActive: true
		},
		{
			title: t('components:Prilezitosti.IČO'),
			name: 'opLead.ico',
			notActive: true
		},
		{
			title: t('components:Prilezitosti.DIČ'),
			name: 'opLead.dic',
			notActive: true
		},
		{
			title: t('components:Prilezitosti.IČ DPH'),
			name: 'opLead.icdph',
			notActive: true
		},
		{
			title: t('components:Prilezitosti.Email'),
			name: PRILEZITOSTI_KEYS.EMAIL,
			notActive: true
		},
		{
			title: t('components:Prilezitosti.Telefón'),
			name: PRILEZITOSTI_KEYS.TELEFON,
			notActive: true
		},
		{
			title: t('components:Prilezitosti.Vytvorená od'),
			name: 'vytvorenaOd'
		},
		{
			title: t('components:Prilezitosti.Typ príležitosti'),
			name: 'typ',
			notActive: true
		},
		{
			title: t('components:Prilezitosti.Kampaň'),
			name: 'kampan'
		},
		{
			title: t('components:Prilezitosti.Cieľová skupina'),
			name: 'cielovaSkupina'
		},
		{
			title: t('components:Prilezitosti.Priradený kanál'),
			name: PRILEZITOSTI_KEYS.KANAL
		},
		{
			title: t('components:Prilezitosti.Produkt'),
			name: PRILEZITOSTI_KEYS.PRODUKT,
			width: 'fit-content',
			notActive: true
		},
		{
			title: t('components:Prilezitosti.Rodina produktov'),
			name: PRILEZITOSTI_KEYS.PRODUKTOVA_RODINA,
			width: 'fit-content',
			notActive: true
		},
		{
			title: t('components:Prilezitosti.Komodita typ'),
			name: PRILEZITOSTI_KEYS.KOMODITA
		},
		{
			title: t('components:Prilezitosti.Zdroj príležitosti'),
			name: PRILEZITOSTI_KEYS.ZDROJ
		},
		{
			title: t('components:Prilezitosti.Agent'),
			name: PRILEZITOSTI_KEYS.AGENT
		},
		{
			title: t('components:Prilezitosti.Poznámka'),
			name: PRILEZITOSTI_KEYS.POZNAMKA,
			notActive: true
		}
	]
}

export const countActiveFilters = (filters) => {
	let numberOfActiveFilters = 0
	Object.keys(filters).forEach((key) => {
		if (!isEmpty(filters[key]) && key !== PRILEZITOSTI_KEYS.SORT && key !== PRILEZITOSTI_KEYS.FULL_TEXT) {
			numberOfActiveFilters += 1
		}
	})
	return numberOfActiveFilters
}

export const getFilterTagOptions = (filters) => {
	const tags = []
	Object.keys(filters)?.forEach((key) => {
		if (filters?.[key] instanceof Array && key !== PRILEZITOSTI_KEYS.SORT) {
			if (key === PRILEZITOSTI_FILTER_OPTIONS.DATUM_VYTOVRENIA) {
				const startDate = filters?.[key]?.[0]
				const endDate = filters?.[key]?.[1]
				tags.push({
					label: `Od ${formatDate(startDate, '-')} - Do ${formatDate(endDate, '-')}`,
					value: `Od ${formatDate(startDate, '-')} - Do ${formatDate(endDate, '-')}`,
					key
				})
			} else {
				filters?.[key]?.forEach((filter) => {
					tags.push({
						label: capitalize(filter?.label),
						value: filter?.value,
						key
					})
				})
			}
		}
	})
	return tags
}

export const removeFilterFromFilters = (filters, removedFilter) => {
	let updatedFilters
	// NOTE: in case of date range picker
	if (removedFilter.key === PRILEZITOSTI_FILTER_OPTIONS.DATUM_VYTOVRENIA) {
		updatedFilters = set({ ...filters }, removedFilter.key, undefined)
	} else {
		const selectedFilterValues = filters?.[removedFilter.key]
		updatedFilters = set(
			{ ...filters },
			removedFilter.key,
			selectedFilterValues.filter((value) => value.value !== removedFilter.value)
		)
	}
	return updatedFilters
}

export const praseFiltersToQueryParams = (filters) => {
	let queryParams = {}

	Object.keys(filters)?.forEach((key) => {
		if (key !== PRILEZITOSTI_FILTER_OPTIONS.PRIRADENIE) {
			if (filters[key] instanceof Array) {
				queryParams = {
					...queryParams,
					[key]: [...filters[key].map((filter) => (filter?.value ? filter.value : filter))]
				}
			} else {
				queryParams = {
					...queryParams,
					[key]: filters[key] || undefined
				}
			}
		}
	})
	return queryParams
}

export const getNameOrCompany = (firstName, lastName, company) => {
	if (firstName && lastName) {
		return `${firstName} ${lastName}`
	}
	if (company) {
		return company
	}
	return '---'
}

export const renderZmluvneVztahy = (vztahy) => {
	if (isEmpty(vztahy)) {
		return '---'
	}

	return vztahy.map((vztah) => (
		<p>{`${vztah?.zmluvnyVztahCislo}: ${vztah?.typ?.nazov} - ${vztah?.komoditaTyp?.nazov} ${formatDate(vztah?.vytvorenyOd, '-', DATE_FORMAT.RENDER_DATE)}`}</p>
	))
}

export const canEditLead = (interakcia, auth, lead) => {
	const user = {
		riesitel: interakcia?.riesitel,
		oddelenie: interakcia?.riesitelIdentita?.oddelenie,
		permissions: auth?.user?.roles || []
	}
	// NOTE: Editing lead where the user is not the assignee and it is not in their department
	if (checkPermissions(user.permissions, [PERMISSIONS.EDIT_LEADS_EXTENDED])) {
		return true
	}
	// NOTE: Editing lead where the user is the assignee
	if (user?.riesitel?.toLowerCase() === lead?.riesitel?.toLowerCase() && checkPermissions(user.permissions, [PERMISSIONS.EDIT_LEADS_OWN])) {
		return true
	}
	// NOTE: Editing lead where the user is not the assignee but the lead is in the user's department
	const leadOddelenieKod = head(lead?.priradeneOddelenie?.nazov?.split('-'))
	return (
		(user?.oddelenie?.kod === leadOddelenieKod || `${user?.oddelenie?.kod}-${user?.oddelenie?.nazov}` === lead?.priradeneOddelenie?.nazov) &&
		checkPermissions(user.permissions, [PERMISSIONS.EDIT_LEADS_BASE])
	)
}

export const canSeizeLead = (oddelenie, lead, auth) => {
	return !lead?.riesitel && (lead?.priradeneOddelenie?.nazov === oddelenie || checkPermissions(auth?.user?.roles, [PERMISSIONS.EDIT_LEADS_EXTENDED]))
}
