export const WARN = 'WARN'
export const INFO = 'INFO'
export const ERROR = 'ERROR'

export const CRASH_ERROR_TYPES = {
	NETWORK: 'NETWORK',
	APPLICATION: 'APPLICATION'
}

export const WIDGETY_ENUMS = {
	FAKTURY: 'FAKTURY',
	ROZPIS_ZALOH: 'ROZPIS_ZALOH',
	RYCHLE_UKONY: 'RYCHLE_UKONY',
	ODBERNE_MIESTA: 'ODBERNE_MIESTA',
	SPLNOMOCNENIA: 'SPLNOMOCNENIA',
	STAV_UCTU: 'STAV_UCTU',
	ZOZNAM_PRODUKTOV_ZMLUV: 'ZOZNAM_PRODUKTOV_ZMLUV',
	SPLATKOVE_KALENDARE: 'SPLATKOVE_KALENDARE',
	DIGITALIZACIA: 'DIGITALIZACIA',
	SPRAVA_SUHLASOV: 'SPRAVA_SUHLASOV',
	PRILEZITOSTI: 'PRILEZITOSTI'
}

export const FILTER_SELECTORS = {
	ROZPISY_ZALOH_PAGE: 'rozpisyZalohPage',
	FAKTURY_PAGE: 'fakturyPage',
	ZMLUVNE_UCTY_PAGE: 'zmluvneUctyPage',
	SIDEBAR_HISTORY: 'sidebarHistory',
	MIESTA_SPOTREBY_PAGE: 'miestaSpotrebyPage',
	STAV_UCTU_PAGE: 'stavUctuPage',
	STAV_UCTU_DETAIL_PAGE: 'stavUctuDetailPage',
	UPOMIENKY_PAGE: 'upomienkyPage',
	PLANY_SPLATOK_CREATE_PAGE: 'planySplatokCreatePage',
	PREPLATKY_PAGE: 'preplatkyPage',
	BLOKOVANIE_UPOMINANIA_PAGE: 'blokovanieUpominaniaPage',
	ZMLUVNE_VZTAHY_PAGE: 'zmluvneVztahyPage',
	OP_VZTAHY_PAGE: 'opVztahyPage',
	OP_DETAIL_PAGE: 'opDetailPage'
}

export const VZTAHY_FILTER_TYP = {
	MOP_SELECTED: 'ZZPSME,ZZPSMT,ZMCKAM,ZMCAM,ZOPR01,ZSTA01,BUR001,ZVIPUP,ZSPRAV',
	MOO_SELECTED: 'ZOPR01'
}

export const VZTAHY_TYP = {
	VZTAH_OPRAVNENEJ_OSOBY: 'ZOPR01'
}

export const SCENARE = {
	PODPISANIE_NA_MIESTE: 'podpisanie-na-mieste',
	ODOSLAT_BEZ_PODPISU: 'odoslat-bez-podpisu',
	VYTLACIT_A_NAHRAT: 'vytlacit-a-nahrat',
	ODOSLAT_NA_PODPIS: 'odoslat-na-podpis',
	NAHRAT: 'nahrat'
}

export const PODPIS_MIESTO = 'Bratislava'

export const NOTIFICATION_TYPES = {
	EMAIL: 'EMAIL',
	PRINTER: 'PRINTER',
	ADDRESS: 'ADDRESS',
	VOID_NOTIFICATION: 'VOID_NOTIFICATION'
}

export const UKONY = {
	ZMENA_UDAJOV_OP: 'ZMENA_UDAJOV_OP',
	ZALOZENIE_OP: 'ZALOZENIE_OP',
	ZMENA_UDAJOV_MS: 'ZMENA_UDAJOV_MS',
	KONZULTACIA_FAKTURY: 'KONZULTACIA_FAKTURY',
	KONZULTACIA_UPOMIENKY: 'KONZULTACIA_UPOMIENKY',
	KONZULTACIA_UCTOVNEHO_DOKLADU: 'KONZULTACIA_UCTOVNEHO_DOKLADU',
	DUPLIKAT_FAKTURY: 'DUPLIKAT_FAKTURY',
	DUPLIKAT_UPOMIENKY: 'DUPLIKAT_UPOMIENKY',
	DUPLIKAT_UCTOVNEHO_DOKLADU: 'DUPLIKAT_UCTOVNEHO_DOKLADU',
	DUPLIKAT_SEPA_MANDATU: 'DUPLIKAT_SEPA_MANDATU',
	OVERENIE_PLATBY: 'OVERENIE_PLATBY',
	PREDDAVKOVE_PLATBY: 'PREDDAVKOVE_PLATBY',
	SPLNOMOCNENIE: 'SPLNOMOCNENIE',
	VSEOBECNY_UKON_PASIVNY: 'VSEOBECNY_UKON_PASIVNY',
	PODPIS_SEPA_MANDATU: 'PODPIS_SEPA_MANDATU',
	PLAN_SPLATOK: 'PLAN_SPLATOK',
	VSEOBECNY_UKON_ANONYMNY: 'VSEOBECNY_UKON_ANONYMNY',
	VSEOBECNY_UKON_AKTIVNY: 'VSEOBECNY_UKON_AKTIVNY',
	SYSTEMOVE_LISTY: 'SYSTEMOVE_LISTY',
	VRATENIE_PREPLATKOV: 'VRATENIE_PREPLATKOV',
	BLOKOVANIE_UPOMINANIA: 'BLOKOVANIE_UPOMINANIA',
	NASTAVENIE_ZU: 'NASTAVENIE_ZU',
	ZMLUVNY_VZTAH_PREPIS: 'ZMLUVNY_VZTAH_PREPIS',
	ZMLUVNY_VZTAH_NOVY: 'ZMLUVNY_VZTAH_NOVY',
	ZMLUVNY_VZTAH_UKONCENIE: 'ZMLUVNY_VZTAH_UKONCENIE',
	ZMLUVNY_VZTAH_NOVY_VAS: 'ZMLUVNY_VZTAH_NOVY_VAS',
	ZMLUVNY_VZTAH_NOVY_DODATOK: 'ZMLUVNY_VZTAH_NOVY_DODATOK',
	ZMLUVNY_VZTAH_ZMENA: 'ZMLUVNY_VZTAH_ZMENA',
	ZMLUVNY_VZTAH_NOVY_EE: 'ZMLUVNY_VZTAH_NOVY_EE',
	ZMLUVNY_VZTAH_NOVY_EE_OBNOVA_MS: 'ZMLUVNY_VZTAH_NOVY_EE_OBNOVA_MS',
	ZMLUVNY_VZTAH_NOVY_GAS: 'ZMLUVNY_VZTAH_NOVY_GAS',
	ZMLUVNY_VZTAH_NOVY_GAS_OBNOVA_MS: 'ZMLUVNY_VZTAH_NOVY_GAS_OBNOVA_MS',
	ZMLUVNY_VZTAH_ZMENA_PRODUKTU_EE: 'ZMLUVNY_VZTAH_ZMENA_PRODUKTU_EE',
	ZMLUVNY_VZTAH_ZMENA_PRODUKTU_GAS: 'ZMLUVNY_VZTAH_ZMENA_PRODUKTU_GAS',
	ZMLUVNY_VZTAH_UKONCENIE_EE_PREPIS: 'ZMLUVNY_VZTAH_UKONCENIE_EE_PREPIS',
	ZMLUVNY_VZTAH_UKONCENIE_GAS_PREPIS: 'ZMLUVNY_VZTAH_UKONCENIE_GAS_PREPIS',
	ZMLUVNY_VZTAH_PREPIS_EE: 'ZMLUVNY_VZTAH_PREPIS_EE',
	ZMLUVNY_VZTAH_PREPIS_GAS: 'ZMLUVNY_VZTAH_PREPIS_GAS',
	PODPIS_ZMLUVNEJ_DOKUMENTACIE: 'PODPIS_ZMLUVNEJ_DOKUMENTACIE',
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_NOVY_ODBER: 'PODPIS_ZMLUVNEJ_DOKUMENTACIE_NOVY_ODBER',
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS: 'PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS',
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_OBNOVA_MS: 'PODPIS_ZMLUVNEJ_DOKUMENTACIE_OBNOVA_MS',
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_VAS: 'PODPIS_ZMLUVNEJ_DOKUMENTACIE_VAS',
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_DODATOK: 'PODPIS_ZMLUVNEJ_DOKUMENTACIE_DODATOK',
	OVERENIE_PIN: 'OVERENIE_PIN'
}

export const MIGRACIA_UKONY_CISELNIK = {
	NOVA_ZMLUVA_EE_MIGRACIA: 5010,
	NOVA_ZMLUVA_EE_PREPIS_MIGRACIA: 5020,
	ZMENA_PRODUKTU_EE_MIGRACIA: 5210,
	UKONCENIE_ZMLUVY_EE_MIGRACIA: 5410,
	ZMENA_DODAVATELA_EE_MIGRACIA: 5510,
	ZMENA_UDAJOV_OP_EMAIL_MIGRACIA: 6001,
	ZMENA_UDAJOV_OP_TELEFON_MIGRACIA: 6002,
	ZMENA_UDAJOV_OP_KORESPONDENCNA_ADRESA_MIGRACIA: 6003,
	ZMENA_ZALOH_MIGRACIA: 6004,
	ZMENA_NASTAVENI_ZU_SPOSOB_PLATBY_MIGRACIA: 6005,
	ZMENA_NASTAVENI_ZU_PREPLATKY_MIGRACIA: 6006,
	ZMENA_NASTAVENI_ZU_EFAKTURA_MIGRACIA: 6007,
	UKONCENIE_ZMLUVY_PREPIS_MIGRACIA: 6008,
	NOVA_ZMLUVA_PREPIS_MIGRACIA: 6009,
	ZMENA_PRODUKTU_MIGRACIA: 6010,
	ZMENA_DODAVATEKA_MIGRACIA: 6011
}

export const UKONY_CISELNIK = {
	ZMENA_UDAJOV_OP: 1,
	PREDDAVKOVE_PLATBY: 3,
	KONZULTACIA_FAKTURY: 4,
	VSEOBECNY_UKON_PASIVNY: 5,
	VSEOBECNY_UKON_AKTIVNY: 6,
	OVERENIE_PLATBY: 7,
	KONZULTACIA_UPOMIENKY: 8,
	ZMENA_UDAJOV_MS: 9,
	SPLNOMOCNENIE: 12,
	PLAN_SPLATOK: 13,
	DUPLIKAT_FAKTURY: 14,
	PODPIS_SEPA_MANDATU: 15,
	SPLNOMOCNENIE_UKONCENIE: 16,
	DUPLIKAT_UPOMIENKY: 17,
	ZALOZENIE_OP: 18,
	ZASLANIE_DUPLIKATU_SEPA_MANDATU: 19,
	PLAN_SPLATOK_SPLNOMOCNENEC: 20,
	PLAN_SPLATOK_DEDIC: 21,
	KONZULTACIA_UCTOVNEHO_DOKLADU: 22,
	DUPLIKAT_UCTOVNEHO_DOKLADU: 23,
	VRATENIE_PREPLATKOV: 24,
	BLOKOVANIE_UPOMINANIA: 25,
	NASTAVENIE_ZU: 28,
	SYSTEMOVE_LISTY: 29,
	ZMLUVNY_VZTAH_NOVY: 35,
	ZMLUVNY_VZTAH_UKONCENIE: 37,
	ZMLUVNY_VZTAH_PREPIS: 38,
	PODPIS_ZMLUVNEJ_DOKUMENTACIE: 41,
	ZMLUVNY_VZTAH_ZMENA: 42,
	GENEROVANIE_PIN: 44,
	OVERENIE_PIN: 45,
	ANONYMNY_VSEOBECNY_UKON: 47,
	ZMENA_UDAJOV_OP_UMRTIE: 50,
	OVERENIE_UDAJOV_OP: 51,
	ZMLUVNY_VZTAH_NOVY_EE: 1010,
	ZMLUVNY_VZTAH_NOVY_EE_OBNOVA_MS: 1030,
	UKONCENIE_ZMLUVY_EE: 1450,
	ZMLUVNY_VZTAH_NOVY_GAS: 2010,
	ZMLUVNY_VZTAH_NOVY_GAS_OBNOVA_MS: 2030,
	ZMLUVNY_VZTAH_NOVY_VAS: 3010,
	ZMLUVNY_VZTAH_NOVY_DODATOK: 3020,
	ZMLUVNY_VZTAH_ZMENA_PRODUKTU_EE: 1210,
	ZMLUVNY_VZTAH_ZMENA_PRODUKTU_GAS: 2210,
	ZMLUVNY_VZTAH_UKONCENIE_EE_PREPIS: 1410,
	ZMLUVNY_VZTAH_UKONCENIE_GAS_PREPIS: 2410,
	UKONCENIE_ZMLUVY_GAS: 2450,
	ZMLUVNY_VZTAH_PREPIS_EE: 1020,
	ZMLUVNY_VZTAH_PREPIS_GAS: 2020,
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_NOVY_ODBER: 4010,
	PODPIS_ZMLUVY_GAS_NOVY_ODBER: 4011,
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS: 4020,
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_PREPIS_GAS: 4021,
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_OBNOVA_MS: 4030,
	PODPIS_ZMLUVY_GAS_OBNOVA_MS: 4031,
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_VAS: 4110,
	PODPIS_ZMLUVNEJ_DOKUMENTACIE_DODATOK: 4210,
	...MIGRACIA_UKONY_CISELNIK
}

export const ROZPIS_ZALOH_CYKLUS = {
	PODLA_FAKTURACNEJ_OBLASTI: 1,
	BEZ_ZALOH: 2,
	MESACNY: 3,
	KVARTALNY: 4,
	POLROCNY: 5,
	ROCNY: 6
}

export const ROZPIS_ZALOH_POLOZKA_STATUS = {
	OTVORENY: 1,
	VYROVNANY: 2
}

export const ROZPIS_ZALOH_VYROVNANIE_STATUS = {
	VYROVNANY: 1,
	SPLATNY_PRED: 2,
	SPLATNY_PO: 3,
	NESPLATNY: 4
}

export const POHLADAVKA_SPLATNOST_STATUS = {
	VYROVNANA: 1,
	SPLATNA_PRED: 2,
	SPLATNA_PO: 3,
	NESPLATNA: 4
}

export const POHLADAVKA_VYROVNANIE_STATUS = {
	OTVORENA: 1,
	VYROVNANA: 2
}

export const POHLADAVKA_ZDROJ_DAT = {
	SAP_ISU: 2,
	FLORES: 4
}

export const BUSINESS_CHANNELS = {
	ZSE_CENTRUM: 1,
	ZAKAZNICKA_LINKA: 2,
	EXTERNY_PREDAJ: 3,
	BACK_OFFICE: 4
}

export const POHLADAVKA_TYP = {
	PRIJATA_PLATBA: {
		value: 5 /*
		label: 'Prijatá platba' */
	},
	ODOSLANA_PLATBA: {
		value: 7 /*
		label: 'Odoslaná platba' */
	},
	ZALOHA: {
		value: 1 /*
		label: 'Záloha' */
	},
	ZALOHA_ZNOVUPRIPOJENIE: {
		value: 15 /*
		label: 'Záloha znovupripojenie' */
	},
	FAKTURA_ZALOHOVA: {
		value: 2 /*
		label: 'Faktúra zálohová' */
	},
	FAKTURA_VYUCTOVACIA: {
		value: 3 /*
		label: 'Faktúra vyúčtovacia' */
	},
	FAKTURA_VYUCTOVACIA_UROKY: {
		value: 19 /*
		label: 'Faktúra vyúčtovacia - úroky */
	},
	FAKTURA_VYUCTOVACIA_PREUCTOVANIE: {
		value: 20 /*
		label: 'Faktúra vyúčtovacia - preúčtovanie */
	},
	FAKTURA_VYUCTOVACIA_VAS: {
		value: 13 /*
		label: 'Faktúra vyúčtovacia VAS' */
	},
	FAKTURA_OPRAVNA: {
		value: 4 /*
		label: 'Faktúra opravná' */
	},
	FAKTURA_OPRAVNA_VAS: {
		value: 14 /*
		label: 'Faktúra opravná VAS' */
	},
	FAKTURA_NEKOMODITA: {
		value: 24 /* ,
		label: 'Faktúra nekomodita' */
	},
	FAKTURA_ZNOVUPRIPOJENIE: {
		value: 16 /*
		label: 'Faktúra znovupripojenie' */
	},
	AKONTO: {
		value: 6 /*
		label: 'Akonto' */
	},
	PREPLATOK_Z_FAKTURACIE: {
		value: 17 /*
		label: 'Preplatok z fakturácie' */
	},
	VRATENIE_AKONTA: {
		value: 11 /*
		label: 'Vrátenie akonta' */
	},
	SPLATKA: {
		value: 10 /*
		label: 'Splátka' */
	},
	UDRZBA_UCTU: {
		value: 8 /*
		label: 'Údržba účtu' */
	},
	STANDARD_KVALITY: {
		value: 12 /*
		label: 'Štandard kvality' */
	},
	ZALOHA_VELKOODBER: {
		value: 18 /*
		label: 'Záloha veľkoodber' */
	},
	OSTATNE: {
		value: 9 /*
		label: 'Ostatné' */
	},
	LEN_PLATBY: {
		// NOTE: added by task CP-836
		value: 'len_platby',
		label: 'Len platby'
	},
	OKREM_PLATIEB: {
		// NOTE: added by task CP-836
		value: 'okrem_platieb',
		label: 'Okrem platieb'
	}
}

export const PREPLATOK_TYP = {
	AKONTO: {
		value: 6 /*
		label: 'Akonto' */
	},
	PREPLATOK_Z_FAKTURACIE: {
		value: 17 /*
		label: 'Preplatok z fakturácie' */
	}
}

export const DIRECTION = {
	ASC: 'asc',
	DESC: 'desc'
}

export const POHLADAVKA_CUSTOM_TYPE = {
	LEN_PLATBY: 'len_platby',
	OKREM_PLATIEB: 'okrem_platieb'
}

export const SPOSOB_PLATBY_DOSLA_PLATBA = {
	PREVODNY_PRIKAZ: 1,
	INKASNY_PRIKAZ: 2,
	POSTOVA_POUKAZKA: 3
}

export const SPOSOB_PLATBY_ODOSLANA_PLATBA = {
	PREVODNY_PRIKAZ: 1,
	POSTOVA_POUKAZKA: 2
}

export const SEPA_MANDAT_STAV = {
	ZADANY: 0,
	AKTIVNY: 1,
	NA_POTVRDENIE: 2,
	ZABLOKOVANY: 3,
	STORNOVANY: 4,
	ZASTARANY: 5,
	UKONCENY: 6
}

export const FORMS = {
	OBCHODNY_PARTNER_EDITACIA: 'OBCHODNY_PARTNER_EDITACIA',
	OBCHODNY_PARTNER_ZALOZENIE: 'OBCHODNY_PARTNER_ZALOZENIE',
	VYTVORENIE_BANKOVEHO_SPOJENIA: 'VYTVORENIE_BANKOVEHO_SPOJENIA',
	IBAN_FIELD_DOSLE_PLATBY: 'IBAN_FIELD_DOSLE_PLATBY',
	IBAN_FIELD_ODOSLANE_PLATBY: 'IBAN_FIELD_ODOSLANE_PLATBY',
	SPLNOMOCNENIA: 'SPLNOMOCNENIA',
	SPLNOMOCNENIA_ZALOZENIE_OP: 'SPLNOMOCNENIA_ZALOZENIE_OP',
	UPRAVA_ZMLUVNEHO_UCTU: 'UPRAVA_ZMLUVNEHO_UCTU',
	ROZPISY_ZALOH: 'ROZPISY_ZALOH',
	PLANY_SPLATOK_ZALOZENIE_OP: 'PLANY_SPLATOK_ZALOZENIE_OP',
	PLANY_SPLATOK_VYTVORENIE_VYBER_FAKTUR: 'PLANY_SPLATOK_VYTVORENIE_VYBER_FAKTUR',
	PLANY_SPLATOK_VYTVORENIE: 'PLANY_SPLATOK_VYTVORENIE',
	VSEOBECNY_UKON_AKTIVNY: 'VSEOBECNY_UKON_AKTIVNY',
	ODOSLANIE_KOPIE_SEPA_MANDATU: 'ODOSLANIE_KOPIE_SEPA_MANDATU',
	VSEOBECNY_UKON_ANONYMNY: 'VSEOBECNY_UKON_ANONYMNY',
	SYSTEMOVE_LISTY: 'SYSTEMOVE_LISTY',
	VRATENIE_PREPLATKOV_VYTVORENIE: 'VRATENIE_PREPLATKOV_VYTVORENIE',
	VRATENIE_PREPLATKOV_VYTVORENIE_VYBER_POHLADAVOK: 'VRATENIE_PREPLATKOV_VYTVORENIE_VYBER_POHLADAVOK',
	BLOKOVANIE_UPOMINANIA_VYTVORENIE: 'BLOKOVANIE_UPOMINANIA_VYTVORENIE',
	BLOKOVANIE_UPOMINANIA_VYTVORENIE_VYBER_FAKTUR: 'BLOKOVANIE_UPOMINANIA_VYTVORENIE_VYBER_FAKTUR',
	NASTAVENIA: 'NASTAVENIA',
	ZMLUVNY_VZTAH_PREPIS: 'ZMLUVNY_VZTAH_PREPIS',
	ZMLUVNY_VZTAH_NOVY: 'ZMLUVNY_VZTAH_NOVY',
	ZMLUVNY_VZTAH_NOVY_ODBERNE_MIESTO: 'ZMLUVNY_VZTAH_NOVY_ODBERNE_MIESTO',
	DIGITALIZACIA: 'DIGITALIZACIA',
	GENERIC_UKON: 'GENERIC_UKON',
	GENERIC_UKON_UKONCIT_INTERAKCIU: 'GENERIC_UKON_UKONCIT_INTERAKCIU',
	GENERIC_UKON_VSEOBECNY_ANONYMNY: 'GENERIC_UKON_VSEOBECNY_ANONYMNY',
	ZMENA_SUHLASU: 'ZMENA_SUHLASU',
	UKON_DETAIL_INFO_EDITACIA: 'UKON_DETAIL_INFO_EDITACIA',
	PRILEZITOSTI_FILTER: 'PRILEZITOSTI_FILTER',
	PRILEZITOSTI_UPRAVA: 'PRILEZITOSTI_UPRAVA'
}

export const KOMODITA_TYP = {
	ELEKTRINA: 1,
	PLYN: 2,
	NEKOMODITA: 10
}

export const ZMLUVNY_UCET_TYP = {
	KOMODITNY: 1,
	NCB: 2
}

export const FAKTURA_TYP = {
	ZALOHOVA: 1,
	VYUCTOVACIA: 2,
	OPRAVNA: 3
}

export const ZMLUVNY_VZTAH_TYP = {
	KOMODITA: 3,
	PREDAJ: 4,
	SLUZBA_KOMODITA: 5,
	SLUZBA_NCB: 6,
	DODATOK: 7,
	VYROBA_KOMODITA: 8
}

export const ZMLUVNY_VZTAH_STAV = {
	NOVY: 1,
	PLATNY: 2,
	UCINNY: 3,
	UKONCENY: 4,
	STORNOVANY: 5
}

export const ZMLUVNY_VZTAH_TECHNICKY_STAV = {
	V_AKTIVACII: 'V_AKTIVACII',
	V_DEAKTIVACII: 'V_DEAKTIVACII',
	V_ZMENE: 'V_ZMENE',
	CHYBNE: 'CHYBNE',
	DOD_V_SPRACOVANI: 'DOD_V_SPRACOVANI',
	DOD_POZIADAVKA_NA_TLAC: 'DOD_POZIADAVKA_NA_TLAC',
	DOD_ODOSLANE_ZAKAZNIKOVI: 'DOD_ODOSLANE_ZAKAZNIKOVI'
}

export const ZMLUVNY_VZTAH_POCET_UKONOV = 3

export const DOKUMENT_TYP_SPLNOMOCNENIA = {
	VLOZIT_SPLNOMOCNENIE: 'VLOZIT_SPLNOMOCNENIE',
	VZOROVE_SPLNOMOCNENIE: 'VZOROVE_SPLNOMOCNENIE'
}

export const UKON_STAV = {
	DRAFT: 0,
	NOVY: 1,
	INVESTIGACIA: 2,
	V_SPRACOVANI: 3,
	SPRACOVANY: 4,
	STORNOVANY: 5,
	CHYBA_SPRACOVANIA: 6,
	ODLOZENY: 10,
	PODMIENENY: 20,
	PREBIEHA_STORNO: 53
}

export const PLAN_SPLATOK_STAV = {
	NEAKTIVNY: 0,
	OTVORENY: 1,
	VYROVNANY: 2,
	ZRUSENY: 3,
	DEAKTIVOVANY_UPOMINANIM: 4,
	DEAKTIVOVANY_MANUALNE: 5
}

export const UKON_VSTUP = {
	EMAIL: 1,
	CHAT: 2,
	LIST: 3,
	OSOBNE: 4,
	TELEFON: 5,
	FAX: 6,
	FACEBOOK: 7,
	WEBFORMULAR: 8
}

export const SPLNOMOCNENIE_STAV_ICON = {
	AKTIVNE: 'green',
	NEAKTIVNE: 'red',
	ZATIAL_NEAKTIVNE: 'gray'
}

export const OBCHODNY_PARTNER_DRUH = {
	DOMACNOST: 1
}

export const OBCHODNY_PARTNER_TYP = {
	MOP: 'MOP',
	MOO: 'MOO',
	OSTATNI: 'OSTATNI'
}

export const OP_SKUPINA_OPRAVNENI = {
	MOO: 0,
	MOP_KAM: 1,
	MOP_SME: 2,
	MOP_AM: 3,
	MOP_TOP_SME: 4
}

export const OP_SEGMENT_BB_MAP = {
	[OP_SKUPINA_OPRAVNENI.MOP_KAM]: 'ZMCKAM',
	[OP_SKUPINA_OPRAVNENI.MOP_SME]: 'ZZPSME',
	[OP_SKUPINA_OPRAVNENI.MOP_AM]: 'ZMCAM',
	[OP_SKUPINA_OPRAVNENI.MOP_TOP_SME]: 'ZZPSMT'
}

export const OP_SEGMENT = {
	[OP_SKUPINA_OPRAVNENI.MOO]: 'MOO',
	[OP_SKUPINA_OPRAVNENI.MOP_KAM]: 'KAM',
	[OP_SKUPINA_OPRAVNENI.MOP_SME]: 'SME',
	[OP_SKUPINA_OPRAVNENI.MOP_AM]: 'AM',
	[OP_SKUPINA_OPRAVNENI.MOP_TOP_SME]: 'TOP SME'
}

export const OP_REGULACIA = {
	EE_REGULOVANY: 'EE reg',
	GAS_REGULOVANY: 'GAS reg',
	EE_NEREGULOVANY: 'EE nereg',
	GAS_NEREGULOVANY: 'GAS nereg'
}

export const OBCHODNY_PARTNER_OSLOVENIE = {
	FIRMA: 4
}

export const TYP_PLATCU_PLANU_SPLATOK = {
	SAM_ZA_SEBA: 'op',
	SPLNOMOCNENEC: 'splnomocnenec',
	DEDIC: 'dedic'
}

export const DOKUMENT_TYP = {
	VSTUPNY: 1,
	VYSTUPNY: 2,
	OSTATNE: 3,
	UKONCENY: 4,
	STORNO: 5
}

export const DOCUMENT_KEYS = {
	VSTUPNY: 'vstupny',
	VYSTUPNY: 'vystupny',
	OSTATNE: 'ostatne',
	UKONCENY: 'ukonceny',
	STORNO: 'storno'
}

export const DOCUMENT_ACTIONS = {
	NA_PODPIS: 1,
	NA_POTVRDENIE: 2
}

export const ENVIRONMENT = {
	TEST: 'TEST',
	PRODUCTION: 'PRODUCTION'
}

export const PAGES = {
	DASHBOARD: 'dashboard',
	OBCHODNY_PARTNER: 'obchodny-partner',
	STAV_UCTU: 'stav-uctu',
	SPRAVA_SUHLASOV: 'suhlasy',
	MIESTA_SPOTREBY: 'miesta-spotreby',
	ZMLUVNE_UCTY: 'zmluvne-ucty',
	ZMLUVY: 'zmluvy',
	FAKTURY: 'faktury',
	UPOMIENKY: 'upomienky',
	ROZPISY_ZALOH: 'rozpisy-zaloh',
	PLANY_SPLATOK: 'plany-splatok',
	SPLNOMOCNENE_OSOBY: 'splnomocnene-osoby',
	GENERIC_UKON: 'genericky-ukon',
	PRILEZITOSTI: 'prilezitosti'
}

export const OBCHODNY_PARTNER_ROLA = {
	PROSPEKT: 4
}

export const FAKTURACNA_PERIODA = {
	ROCNE: 1,
	POLROCNE: 2,
	STVRTROCNE: 4,
	MESACNE: 12
}

export const MERANIE_TYP = {
	A: 'A',
	B: 'B',
	C: 'C',
	N: 'N'
}

export const DRUH_MIESTA_SPOTREBY_HLAVNE = {
	BYTY: 1,
	RODINNY_DOM: 20,
	SPOLOCNE_PRIESTORY: 9,
	GARAZ: 7,
	ZAHRADA: 22
}

export const FAZY_POCET = {
	F3: 3,
	F1: 1
}

export const TARIFA = {
	TARIFA_1T: '1T',
	TARIFA_NT: 'NT',
	TARIFA_VT: 'VT',
	TARIFA_PLYN: 'PLYN'
}

export const STAV_INDIKATORA = {
	GRAY: 'GRAY',
	GREEN: 'GREEN',
	AMBER: 'AMBER',
	RED: 'RED'
}

export const DOCUMENT_CONTENT_TYPE = {
	ZSEE_EMAIL: 'zsee/mail'
}

export const SUHLAS_HODNOTA = {
	SUHLAS: 'SUHLAS',
	NAMIETKA: 'NAMIETKA'
}

export const SUHLAS_TYPE = {
	EMAIL: 'EMAIL',
	PHONE: 'PHONE',
	INCOMING_PAYEMNT: 'INCOMING_PAYEMNT',
	OUTCOMING_PAYMENT: 'OUTCOMING_PAYMENT',
	EMAILS: 'EMAILS'
}

export const ODBERNE_MIESTO_OBSLUHA = {
	STANDARDNA: 'standardna',
	LIMITOVANA: 'limitovana'
}

export const PAYMENT_METHOD_TYPE = {
	INCOMING: 'doslePlatbySposob',
	OUTCOMING: 'odoslanePlatbySposob'
}

export const PONUKA_MODE = {
	MIESTO_SPOTREBY: 'MIESTO_SPOTREBY',
	ZMLUVNY_VZTAH: 'ZMLUVNY_VZTAH',
	OBCHODNY_PARTNER: 'OBCHODNY_PARTNER'
}

export const ADDRESS_SOURCE = {
	SPPD: 'SPPD',
	ZSEE: 'ZSEE'
}

export const RADIO_GROUP_FIELD_TYPE = {
	NUMBER: 'NUMBER',
	DECIMAL: 'DECIMAL',
	SELECT: 'SELECT',
	TEXT: 'TEXT'
}

export const LOKALIZACIA_TYP = {
	VEDLA: 'VE',
	OPROTI: 'OP',
	PRI: 'PR',
	PR: 'PRI',
	ZA: 'ZA',
	BLIZKO: 'BL',
	VLAVO: 'VL',
	VPRAVO: 'VP',
	TRAFOSTANICA: 'TS',
	VELKOODBER: 'VO',
	A: 'A',
	B: 'B',
	C: 'C',
	D: 'D',
	D1: 'D1',
	D2: 'D2',
	D3: 'D3',
	D4: 'D4'
}

export const ADDRESS_SEARCH_FIELD_MODE = {
	SUPISNE_CISLO: 'SUPISNE_CISLO',
	PARCELNE_CISLO: 'PARCELNE_CISLO'
}

export const STORNO_DOVOD = {
	STORNO_POUZIVATELOM: 'STORNO_POUZIVATELOM',
	STORNO_POUZIVATELOM_BEZ_NOTIFIKACIE: 'STORNO_POUZIVATELOM_BEZ_NOTIFIKACIE'
}

export const ODPOJENIE_STATUS = {
	NIEJE_ODPOJENE: 0
}

export const UKON_LIST_ROW_TYPE = {
	ONE_CELL: 'ONE_CELL'
}

export const DATE_FORMAT = {
	RENDER_DATE: 'DD.MM.YYYY'
}

export const UNKNOWN_DATE = '9999-12-31'

export const PREUKAZ_TYP = {
	ISIC: 'ISIC',
	ITIC: 'ITIC',
	EURO26: 'EURO26'
}

export const DEFINICIA_TYP = {
	KONTAKTNY_TELEFON: 5,
	KONTAKTY_EMAIL: 6,
	E_FAKTURA: 7,
	E_PLATBA: 8,
	E_PREPLATKY: 9
}

export const PREVADZKOVATEL_SIETE = {
	VSE: 'ED-VSE',
	SSE: 'ED-SSE',
	ZSE: 'ED-ZSE'
}

export const TYP_SUHLASU = {
	SUHLAS: 2,
	OPRAVNENY_ZAUJEM: 1
}

export const SUHLAS_TYP_VYJADRENIA = {
	SUHLAS: 'Súhlas',
	NESUHLAS: 'Nesúhlas',
	NAMIETKA: 'Námietka',
	NAMIETKA_NA_SCHVALENIE: 'Námietka na schválenie'
}

export const SUHLAS_STAV = {
	RED: 'RED',
	GREEN: 'GREEN',
	GRAY: 'GRAY'
}

export const BIZNIS_REFERENCIA_TYP = {
	SHAREPOINT: {
		id: 1,
		nazov: 'Sharepoint'
	},
	RECORDINGS: {
		id: 2,
		nazov: 'Recordings'
	},
	DOCUMENT_URL: {
		id: 3,
		nazov: 'DokumentUrl'
	},
	UKON_ID: {
		id: 4,
		nazov: 'Úkon ID'
	},
	ONLINE_UCET: {
		id: 5,
		nazov: 'OnlineUcet'
	}
}

export const SUHLASY_KATEGORIA = {
	BEZ_OP: 1,
	CRIF: 2,
	DIGITALIZACIA: 3,
	MARKETING: 4,
	OPRAVNENE_ZAUJMY: 5,
	OPRAVNENE_ZAUJMY_KOMUNIKACIA: 6,
	PRIESKUM: 7,
	TECHNICKE_OPRAVNENE_ZAUJMY: 8
}

export const SUHLASY_WIDGET_KATEGORIA = SUHLASY_KATEGORIA.MARKETING

export const OP_IDENTIFIKATOR_TYP = {
	CISLO_OP_Z_DISTRIBUCNEHO_SYSTEMU: 1,
	CISLO_Z_OBCHODNEHO_REGISTRA: 2,
	CISLO_ZO_ZIVNOSTENSKEHO_REGISTRA: 3
}

export const EXTERNAL_INTEGRATIONS = {
	LIVE_AGENT: 'liveagent',
	FINESSE: 'finesse',
	CALL250: 'call250',
	KONTAKTNY_TELEFON_QUERY: 'kontaktnyTelefon',
	ID_QUERY: 'externalId',
	SOURCE_QUERY: 'source',
	EXTERNAL_SESSION_ID: 'externalSessionId',
	ACTIONS: {
		TITLE_UPDATE: 'titleUpdate'
	}
}

export const ZMLUVA_TYP_SHORTCUT = {
	VYR: 'VYR',
	NCB: 'NCB',
	VAS: 'VAS',
	EE_DOD: 'EE DOD',
	EE: 'EE',
	GAS_DOD: 'GAS DOD',
	GAS: 'GAS',
	NCB_VAS: 'NCB VAS'
}

export const PRILEZITOSTI_FILTER_OPTIONS = {
	STAV: 'stav',
	ZDROJ: 'zdroj',
	PRODUKT: 'produkt',
	PRODUKTOVA_RODINA: 'produktovaRodina',
	KOMODITA_TYP: 'komoditaTyp',
	AGENT: 'agent',
	KANAL: 'kanal',
	DATUM_VYTOVRENIA: 'datumVytvorenia',
	KAMPAN: 'kampan',
	CIELOVA_SKUPINA: 'cielovaSkupina',
	PRIRADENIE: 'priradenie'
}

export const PRILEZITOSTI_PAGE_SIZE_OPTIONS = {
	10: 10,
	20: 20,
	50: 50,
	100: 100
}

export const PRILEZITOSTI_STAV = {
	NOVA: 1,
	ROZPRACOVANA: 2,
	NEUSPESNA: 3,
	UZAVRETA: 4,
	ODLOZENA: 5,
	AKCEPTOVANA: 6,
	NEAKCEPTOVANA: 7
}

export const PRILEZITOSTI_KEYS = {
	SORT: 'triedenie',
	FULL_TEXT: 'fulltext',
	STAV: 'stav.nazov',
	ZDROJ: 'zdroj.nazov',
	KANAL: 'priradenyKanal.nazov',
	PRODUKT: 'prilezitostProdukty.produktNazov',
	PRODUKTOVA_RODINA: 'prilezitostProdukty.produktovaRodinaNazov',
	KOMODITA: 'prilezitostProdukty.komoditaTypNazov',
	AGENT: 'riesitel',
	LEAD: 'leadId',
	CISLO_OP: 'opLead.cislo',
	MENO_OP: 'opLead.meno',
	PRIEZVISKO_OP: 'opLead.priezvisko',
	OBCHODNY_NAZOV: 'opLead.obchodneMeno',
	TELEFON: 'opLead.kontaktnyTelefon',
	EMAIL: 'opLead.kontaktnyEmail',
	VYTVORENY_OD: 'vytvorenaOd',
	POZNAMKA: 'poznamka'
}

export const CANCEL_REQUEST = 'CANCEL_REQUEST'

export const HISTORIA_UKONOV_PAGE_SIZE = 25
export const MAX_HODNOTA_ODPOCTU = 999999
export const MAX_POZNAMKA_LENGTH = 2000
export const PROTECTION_INTERVAL_FOR_SUBMITTING = 300

export const PROTECTION_INTERVAL_FOR_SEARCHING = 700

export const MAX_VYSTUPNY_DOKUMENT_SIZE = 6

export const LONG_PRESS_DURATION = 500
