import React from 'react'
import Async from 'react-select/async'
import PropTypes from 'prop-types'
import { find, throttle } from 'lodash'
import cx from 'classnames'

class AsyncSelectField extends React.Component {
	state = {
		isLoading: false,
		open: false
	}

	constructor(props) {
		super(props)
		this.selectRef = React.createRef()
	}

	componentDidUpdate(prevProps) {
		const { isDisabled } = this.props
		if (isDisabled != prevProps.isDisabled) {
			this.setState({
				open: false
			})
		}
	}

	handleMenuOpen = () => {
		this.setState({
			open: true
		})
	}

	handleBlur = () => {
		const focusedElement = document.activeElement
		if (focusedElement && !focusedElement.className.includes('react-select')) {
			this.setState({
				open: false
			})
		} else {
			throttle(this.selectRef.current.focus(), 150)
		}
	}

	handleChange = (option) => {
		this.setState(
			{
				open: false
			},
			() => {
				const { isMulti, input, onlyValue = true } = this.props
				if (isMulti) {
					if (onlyValue) {
						const parsedValue = option?.map((opt) => opt?.value)
						if (parsedValue) {
							input.onChange([...parsedValue])
						}
					} else {
						input.onChange([...option])
					}
				} else if (onlyValue) {
					const val = option ? option.value : null
					input.onChange(val)
				} else {
					input.onChange(option)
				}
			}
		)
	}

	loadOptionsWrapper = async (input) => {
		const data = await this.props.loadOptions(input, this)
		return data
	}

	render() {
		const {
			input,
			meta: { touched, error },
			selectOptions,
			placeholder,
			onlyValue = true,
			isMulti,
			...props
		} = this.props
		const { open } = this.state

		let val = input.value !== undefined ? input.value : null
		if (onlyValue) {
			val = find(selectOptions, function (option) {
				return option.value == val
			})
		}

		return (
			<div className={cx('select-wrapper async-select', { 'has-error': touched && error })}>
				<Async
					value={val || null}
					noOptionsMessage={() => 'Nič sa nenašlo.'}
					placeholder={placeholder || 'Zvoľte možnosť'}
					defaultOptions={selectOptions}
					loadOptions={this.loadOptionsWrapper}
					isClearable
					classNamePrefix='react-select'
					cacheOptions={false}
					{...props}
					// NOTE: next lines are for IE hot fix https://github.com/JedWatson/react-select/issues/1020
					onBlur={this.handleBlur}
					onChange={(e) => this.handleChange(e)}
					onMenuOpen={this.handleMenuOpen}
					menuIsOpen={open}
					ref={this.selectRef}
					isMulti={isMulti}
				/>
				<div className='text-danger'>{touched ? error : ''}</div>
			</div>
		)
	}
}

AsyncSelectField.propTypes = {
	selectOptions: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.any.isRequired,
			label: PropTypes.string.isRequired
		})
	),
	loadOptions: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	input: PropTypes.shape({
		value: PropTypes.any,
		onChange: PropTypes.func.isRequired
	}).isRequired,
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string
	}).isRequired,
	isDisabled: PropTypes.bool
}

AsyncSelectField.defaultProps = {
	input: {
		value: null
	}
}

export default AsyncSelectField
