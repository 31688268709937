import React from 'react'
import { connect } from 'react-redux'
import { change, Field } from 'redux-form'
import { compose, bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { withTranslation } from 'react-i18next'

import { get, isEmpty, isEqual } from 'lodash'

// components
import ImportPodpisanychDokumentovModal from '../../Modals/ImportPodpisanychDokumentovModal'

// utils
import { DOCUMENT_ACTIONS, DOKUMENT_TYP, FORMS, MAX_VYSTUPNY_DOKUMENT_SIZE } from '../../../utils/enums'
import { downloadPdfDocument, openDataUriWindow } from '../../../utils/files'
import { parseDocumentUrl } from '../../../utils/genericUkon'

// config
import { EDIT_MODE, FIELD_PATH } from '../../../containers/GenericUkon/genericUkonConfig'

// actions
import PodpisovanieDokumentovActions from '../../../actions/PodpisovanieDokumentov'

class VystupneDokumentyField extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
		field: PropTypes.string.isRequired,
		value: PropTypes.any,
		signedPdf: PropTypes.shape({
			data: PropTypes.shape(),
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}),
		readPdf: PropTypes.shape({
			data: PropTypes.shape(),
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}),
		templatesPdf: PropTypes.shape({
			data: PropTypes.array,
			isLoading: PropTypes.bool.isRequired,
			isFailure: PropTypes.bool.isRequired
		}),
		generovatFormulare: PropTypes.bool.isRequired,
		povinny: PropTypes.bool.isRequired,
		akcia: PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string
		}),
		kanal: PropTypes.shape({
			id: PropTypes.number,
			nazov: PropTypes.string,
			popis: PropTypes.string
		}).isRequired,
		dokumentTyp: PropTypes.shape(),
		podpisovanieDokumentovAction: PropTypes.shape({
			readFile: PropTypes.func.isRequired,
			signFile: PropTypes.func.isRequired,
			clearSignFile: PropTypes.func.isRequired,
			clearReadFile: PropTypes.func.isRequired,
			setNotification: PropTypes.func.isRequired,
			clearNotification: PropTypes.func.isRequired,
			importSignedFile: PropTypes.func.isRequired,
			importConfirmedFile: PropTypes.func.isRequired,
			loadPdfForSigningFromCes: PropTypes.func.isRequired,
			resetTemplatesPdf: PropTypes.func.isRequired
		}).isRequired,
		editMode: PropTypes.string.isRequired,
		ukonNovy: PropTypes.shape().isRequired
	}

	state = {
		showImportSignedPdfModal: false,
		actualSignatureKey: null,
		dokument: null
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true

		const { ukonNovy, podpisovanieDokumentovAction, generovatFormulare, editMode } = this.props

		// Vymazem podpisany subor z reduxu
		podpisovanieDokumentovAction.clearSignFile()
		podpisovanieDokumentovAction.resetTemplatePdf()

		if (generovatFormulare && editMode == EDIT_MODE.CONFIRM) {
			podpisovanieDokumentovAction.loadPdfsForSigning(get(ukonNovy, 'id'))
		}
	}

	componentWillUnmount() {
		this._mounted = false

		const { podpisovanieDokumentovAction, value, dispatch, editMode } = this.props

		// Vymazem podpisany subor z reduxu
		podpisovanieDokumentovAction.clearSignFile()
		podpisovanieDokumentovAction.clearReadFile()
		podpisovanieDokumentovAction.resetTemplatesPdf()

		// NOTE: vymaze vystupne dokumenty, ktore boli nahrate v confirm page
		if (editMode == EDIT_MODE.CONFIRM) {
			const filterDocuments = value?.filter((dokument) => !dokument?.autoAttach && !dokument?.signatureKey) || []
			dispatch(change(FORMS.GENERIC_UKON, FIELD_PATH.DOKUMENTY, [...filterDocuments]))
		}
	}

	onDeleteFile = async () => {}

	validate = (value) => {
		const { povinny, t, templatesPdf } = this.props
		const vystupneDokumenty = value?.filter((dokument) => dokument?.dokumentTyp?.id === DOKUMENT_TYP.VYSTUPNY)

		let povinnePodpisy = 0
		let povinnePrecitanie = 0

		templatesPdf.data?.forEach((template) => {
			const isNotSigned = isEmpty(vystupneDokumenty?.find((dokument) => dokument?.signatureKey === template?.signatureKey))
			if (template?.akcia?.id === DOCUMENT_ACTIONS.NA_PODPIS && isNotSigned) {
				povinnePodpisy += 1
			}
			if (template?.akcia?.id === DOCUMENT_ACTIONS.NA_POTVRDENIE && isNotSigned) {
				povinnePrecitanie += 1
			}
		})

		if (povinnePrecitanie > 0) {
			if (povinnePrecitanie >= 1) {
				return t('translation:Common.validate.Dokumenty musia byť prečítané')
			}
			return t('translation:Common.validate.Dokument musí byť prečítaný')
		}

		if (povinnePodpisy > 0) {
			if (povinnePodpisy >= 1) {
				return t('translation:Common.validate.Dokument musí byť podpísaný')
			}
			return t('translation:Common.validate.Dokumenty musia byť podpísané')
		}

		if (povinny && isEmpty(vystupneDokumenty)) {
			return t('translation:Common.validate.Výstupné dokumenty sú povinné')
		}
	}

	getButtonsConfig = (templatePdf) => {
		const { value } = this.props

		let showNahratDokumentButton = false
		let showPodpisatDokumentButton = false
		let showVytlacitDokumentButton = false

		const vystupneDokumenty = value?.filter((dokument) => dokument?.dokumentTyp?.id === DOKUMENT_TYP.VYSTUPNY)
		const isNotSigned = isEmpty(vystupneDokumenty?.find((dokument) => dokument?.signatureKey === templatePdf?.signatureKey))

		const povinnyPodpis = templatePdf?.akcia?.id === DOCUMENT_ACTIONS.NA_PODPIS

		if (povinnyPodpis && isNotSigned) {
			showNahratDokumentButton = true
			showPodpisatDokumentButton = true
			showVytlacitDokumentButton = true
		}

		return {
			showNahratDokumentButton,
			showPodpisatDokumentButton,
			showVytlacitDokumentButton
		}
	}

	handleImportSignedDocuments = ({ name, uploadedDocumentAsBase64, type }) => {
		const { podpisovanieDokumentovAction } = this.props
		const { actualSignatureKey, dokument } = this.state

		// set uploaded file to redux store
		podpisovanieDokumentovAction.importSignedFile(name, uploadedDocumentAsBase64, type, actualSignatureKey, dokument?.poradie, dokument?.poradieWacom)

		this.setState({
			showImportSignedPdfModal: false,
			actualSignatureKey: null,
			dokument: null
		})
	}

	handleImportConfirmedDocuments = (document) => {
		const { podpisovanieDokumentovAction } = this.props

		// set confirmed file to redux store
		podpisovanieDokumentovAction.importConfirmedFile(document)
	}

	componentDidUpdate(prevProps, prevState) {
		const { dispatch, value, signedPdf, readPdf, dokumentTyp, templatesPdf } = this.props
		const { actualSignatureKey } = this.state

		// NOTE: auto adding of generated documents
		if (!isEqual(prevProps.templatesPdf.data, templatesPdf.data)) {
			const dokumenty = []
			templatesPdf.data?.forEach((template) => {
				if (isEmpty(template?.akcia)) {
					dokumenty.push({
						...template,
						dokumentTyp: template.typ,
						autoAttach: true
					})
				}
			})

			// NOTE: remove existing document with flag isTemplate
			const filterDocuments = value?.filter((dokument) => !dokument?.autoAttach) || []
			dispatch(change(FORMS.GENERIC_UKON, FIELD_PATH.DOKUMENTY, [...filterDocuments, ...dokumenty]))
		}

		// NOTE: check and add signed pdf from wacom to field value
		if (!isEqual(prevProps.signedPdf.data, signedPdf.data) && signedPdf.data) {
			const { name, type, dataAsBase64, akcia, poradie, poradieWacom } = signedPdf.data

			const newSignedDocument = {
				name,
				type,
				dataAsBase64,
				dokumentTyp,
				url: parseDocumentUrl({ contentType: type, data: dataAsBase64 }),
				podpisManualne: !signedPdf.data?.wacom,
				podpisWacom: signedPdf.data?.wacom,
				zmenitDokument: !signedPdf.data?.wacom,
				signatureKey: signedPdf.data?.signatureKey,
				akcia,
				poradie,
				poradieWacom,
				precitanyOd: new Date().toISOString()
			}

			// NOTE: remove document when re-upload existing document
			const filteredDocuments = value?.filter((dokument) => !isEqual(dokument?.signatureKey, actualSignatureKey))

			dispatch(change(FORMS.GENERIC_UKON, FIELD_PATH.DOKUMENTY, [...filteredDocuments, newSignedDocument]))

			if (!isEqual(prevState.actualSignatureKey, actualSignatureKey)) {
				this.setState({
					actualSignatureKey: null,
					dokument: null
				})
			}
		}

		// NOTE: check and add read pdf from wacom to field value
		if (!isEqual(prevProps.readPdf.data, readPdf.data) && readPdf.data) {
			const { id, name, type, akcia, poradie, poradieWacom, dataAsBase64, url } = readPdf.data

			const newReadDocument = {
				id,
				name,
				type,
				dataAsBase64,
				dokumentTyp,
				url,
				signatureKey: readPdf.data?.signatureKey,
				precitaneWacom: readPdf.data?.wacom,
				precitaneManualne: !readPdf.data?.wacom,
				akcia,
				poradie,
				poradieWacom,
				precitanyOd: new Date().toISOString()
			}

			// NOTE: remove the document when reconfirming an existing document.
			const filteredDocuments = value?.filter((dokument) => !isEqual(dokument?.signatureKey, readPdf.data?.signatureKey))

			dispatch(change(FORMS.GENERIC_UKON, FIELD_PATH.DOKUMENTY, [...filteredDocuments, newReadDocument]))

			if (!isEqual(prevState.actualSignatureKey, actualSignatureKey)) {
				this.setState({
					actualSignatureKey: null
				})
			}
		}
	}

	processWacomConfirmation = async (templatePdf) => {
		const { podpisovanieDokumentovAction, ukonNovy } = this.props

		if (templatePdf?.id) {
			const dokumentData = await downloadPdfDocument(templatePdf.id, get(ukonNovy, 'id'))
			podpisovanieDokumentovAction.readFile({ ...templatePdf, data: dokumentData })
		} else {
			podpisovanieDokumentovAction.readFile({ ...templatePdf, data: templatePdf?.dataAsBase64 })
		}
	}

	render() {
		const { field, value, t, podpisovanieDokumentovAction, templatesPdf, editMode, error } = this.props
		const { showImportSignedPdfModal } = this.state

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.CONFIRM) {
			const vystupneDokumenty = value?.filter((dokument) => dokument?.dokumentTyp?.id === DOKUMENT_TYP.VYSTUPNY) || []

			// NOTE: filter only generated documents with actions (NA_PODPIS or NA_SCHVALENIE) and those on whom no further action has been taken
			const generatedDocumentsForSignature = templatesPdf.data?.filter((template) => {
				return (
					template?.akcia?.id !== DOCUMENT_ACTIONS.NA_POTVRDENIE &&
					!vystupneDokumenty?.find((dokument) => template?.signatureKey === dokument?.signatureKey)
				)
			})

			const generatedDocumentsForConfirmation = templatesPdf.data?.filter((template) => {
				return (
					template?.akcia?.id !== DOCUMENT_ACTIONS.NA_PODPIS &&
					!vystupneDokumenty?.find((dokument) => template?.signatureKey === dokument?.signatureKey)
				)
			})

			if (templatesPdf.isLoading) {
				return (
					<div className={cx('generic-field with-background inner-box')}>
						<p style={{ width: '100%', textAlign: 'center' }}>{t('components:GenericFields.Prebieha generovanie dokumentov')}</p>
						<div className='loader-box'>
							<div className='loader' />
						</div>
					</div>
				)
			}

			return (
				<>
					{showImportSignedPdfModal && (
						<ImportPodpisanychDokumentovModal
							modalTitle={t('components:GenericFields.Vloženie podpísaného dokumentu')}
							onCloseButton={() =>
								this.setState({
									showImportSignedPdfModal: false,
									actualSignatureKey: null
								})
							}
							maxSizeInMB={MAX_VYSTUPNY_DOKUMENT_SIZE}
							onSubmit={this.handleImportSignedDocuments}
						/>
					)}
					<div data-name={field} className={cx('generic-field with-background inner-box', { 'danger-box': error })}>
						{vystupneDokumenty?.length > 0 && (
							<div>
								<strong>{t('components:GenericFields.Výstupné dokumenty')}</strong>
								{vystupneDokumenty?.map((dokument) => {
									return (
										<div
											style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
											key={dokument.id}
										>
											<div>
												<a
													className='file file-name'
													onClick={() => openDataUriWindow(dokument?.id || dokument?.url, dokument?.name)}
													data-type='general'
													style={{ cursor: 'pointer', marginRight: '15px' }}
												>
													{dokument?.name}
												</a>
												{(dokument?.precitaneManualne || dokument?.precitaneWacom) && (
													<span className='label' style={{ marginLeft: '15px' }} data-color='green'>
														{t('components:PodpisovanieDokumentov.Výstupný dokument prečítaný')}
													</span>
												)}
												{(dokument?.podpisManualne || dokument?.podpisWacom) && (
													<span className='label' style={{ marginLeft: '15px' }} data-color='green'>
														{t('components:PodpisovanieDokumentov.Výstupný dokument podpísaný')}
													</span>
												)}
												{dokument?.autoAttach && (
													<span className='label' data-color='green' style={{ marginLeft: '15px' }}>
														{t('components:GenericFields.Bude odoslané po dokončení úkonu')}
													</span>
												)}
											</div>
											{dokument?.zmenitDokument && (
												<div className='attr-vystupne-dokumenty-buttons'>
													<button
														type='button'
														className='button small'
														data-type='outline'
														data-color='blue'
														style={{ height: '32px' }}
														onClick={() =>
															this.setState({
																showImportSignedPdfModal: true,
																actualSignatureKey: get(dokument, 'signatureKey'),
																dokument
															})
														}
													>
														{t('components:GenericFields.Zmeniť dokument')}
													</button>
												</div>
											)}
											{dokument?.akcia?.id === DOCUMENT_ACTIONS.NA_POTVRDENIE && (
												<div className='attr-vystupne-dokumenty-buttons'>
													{dokument?.precitaneWacom && (
														<button
															type='button'
															className='button small'
															data-type='outline'
															data-color='blue'
															style={{ height: '32px' }}
															onClick={() => this.processWacomConfirmation(dokument)}
														>
															{t('components:GenericFields.Znova prečítať')}
														</button>
													)}
												</div>
											)}
										</div>
									)
								})}
							</div>
						)}
						<div>
							{!isEmpty(generatedDocumentsForConfirmation) && (
								<div>
									<strong>
										{generatedDocumentsForConfirmation?.length > 1
											? t('components:GenericFields.Dokumenty na prečítanie')
											: t('components:GenericFields.Dokument na prečítanie')}
									</strong>
									{generatedDocumentsForConfirmation?.map((templatePdf) => {
										return (
											<div className='generate-document-box'>
												<div>
													<div style={{ marginTop: '10px' }}>
														<a
															className='file'
															onClick={() => openDataUriWindow(templatePdf?.id || templatePdf?.url, templatePdf.name)}
															data-type='general'
															style={{ cursor: 'pointer', marginRight: '15px' }}
														>
															{templatePdf.name}
														</a>
													</div>
												</div>
												<div className='attr-vystupne-dokumenty-buttons'>
													<button
														type='button'
														className='button small'
														data-type='outline'
														data-color='blue'
														style={{ height: '32px' }}
														onClick={() => {
															openDataUriWindow(templatePdf?.id || templatePdf?.url, templatePdf.name)
															this.handleImportConfirmedDocuments(templatePdf)
														}}
													>
														{t('components:GenericFields.Prečítať bez zariadenia')}
													</button>
													<button
														type='button'
														className='button small'
														data-color='blue'
														style={{ height: '32px' }}
														onClick={() => this.processWacomConfirmation(templatePdf)}
													>
														{t('components:GenericFields.Prečítať')}
													</button>
												</div>
											</div>
										)
									})}
								</div>
							)}
							{!isEmpty(generatedDocumentsForSignature) && (
								<div style={{ marginTop: 8 }}>
									<strong>
										{generatedDocumentsForSignature?.length > 1
											? t('components:GenericFields.Dokumenty na podpis')
											: t('components:GenericFields.Dokument na podpis')}
									</strong>
									{generatedDocumentsForSignature?.map((templatePdf) => {
										const buttonsConfig = this.getButtonsConfig(templatePdf)
										return (
											<div className='generate-document-box'>
												<div>
													<div style={{ marginTop: '10px' }}>
														<a
															className='file'
															onClick={() => openDataUriWindow(templatePdf.url, templatePdf.name)}
															data-type='general'
															style={{ cursor: 'pointer', marginRight: '15px' }}
														>
															{templatePdf.name}
														</a>
													</div>
												</div>
												<div className='attr-vystupne-dokumenty-buttons'>
													{buttonsConfig.showNahratDokumentButton && (
														<button
															type='button'
															className='button small'
															data-type='outline'
															data-color='blue'
															style={{ height: '32px' }}
															onClick={() => {
																this.setState({
																	showImportSignedPdfModal: true,
																	actualSignatureKey: get(templatePdf, 'signatureKey'),
																	dokument: templatePdf
																})
															}}
														>
															{t('components:GenericFields.Nahrať podpísané dokumenty')}
														</button>
													)}
													{buttonsConfig.showPodpisatDokumentButton && (
														<button
															type='button'
															className='button small'
															data-color='blue'
															style={{ height: '32px' }}
															onClick={() =>
																podpisovanieDokumentovAction.signFile({
																	...templatePdf,
																	data: get(templatePdf, 'dataAsBase64')
																})
															}
														>
															{t('components:GenericFields.Podpísať')}
														</button>
													)}
													{buttonsConfig.showVytlacitDokumentButton && (
														<button
															type='button'
															className='button'
															data-type='icon-button'
															data-icon='print'
															style={{ height: '32px', width: '32px' }}
															onClick={() => {
																openDataUriWindow(templatePdf.url, templatePdf.name)
															}}
														/>
													)}
												</div>
											</div>
										)
									})}
								</div>
							)}
							{/* NOTE: Do not remove due to the validation of the field in the form */}
							<Field name={field} validate={this.validate} component='input' type='hidden' />
							{error && <div className='text-danger'>{error}</div>}
						</div>
					</div>
				</>
			)
		}
		return null
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	podpisovanieDokumentovAction: bindActionCreators(PodpisovanieDokumentovActions, dispatch)
})

const mapStateToProps = (state) => ({
	ukonNovy: get(state, 'ukony.ukonNovy'),
	templatesPdf: get(state, 'podpisovanieDokumentov.templatesPdf'),
	signedPdf: get(state, 'podpisovanieDokumentov.signedPdf'),
	readPdf: get(state, 'podpisovanieDokumentov.readPdf'),
	kanal: get(state, 'auth.businessChannel.actual')
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(VystupneDokumentyField)
